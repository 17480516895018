import { InformationCircleIcon } from '@heroicons/react/20/solid'

export default function NewVersion({ isVisible }: { isVisible: boolean }) {
	if (!isVisible) return null

	return (
		<div className="flex flex-none items-center justify-center gap-x-2 border-b border-blue-200 bg-blue-50 px-3 py-1.5">
			<div className="flex flex-shrink-0">
				<InformationCircleIcon
					aria-hidden="true"
					className="h-4 w-4 text-blue-400"
				/>
			</div>
			<div className="flex flex-row items-center gap-x-1.5 leading-none">
				<h3 className="text-sm font-medium text-blue-800">
					New version:
				</h3>
				<div className="text-sm leading-none text-blue-800">
					Please refresh the page to use the latest version.
				</div>
			</div>
		</div>
	)
}
